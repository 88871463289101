.page {
    width: 216mm;
    min-height: 279mm;
    padding: 5mm 10mm 10mm 10mm;
    background: white;
}

.page_landscape {
    width: 297mm;
    min-height: 210mm;
    padding: 5mm 10mm 10mm 10mm;
    background: white;
}


.page, .page_landscape {
    tr {
        word-break: break-word;
    }
}

.letter-head {
    text-align: center;
}

.letter-head p, h3 {
    margin-bottom: 0;
    color: #ff0000;
}

#attendance-text > :not(caption) > * > * {
    padding: 0 !important;
}


@media print {
    @page {
        @bottom-left {
            content: 'DMIS';
        }
        margin-top: 20mm;
    }

    p {
        margin-bottom: 0.5rem !important;
    }

    .d-print {
        display: flex !important;
    }

    #attendance {
        font-size: 10px !important;
    }

    #attendance > :not(caption) > * > * {
        padding: 5px !important;
    }

    /* Table border color */
    table.table-bordered {
        border: 2px solid #000;
    }

    /* Table header background color */
    table.table-bordered thead {
        background-color: #000 !important;
        color: #fff;
        border: 2px solid #000;
    }

    /* Table header cell styles */
    table.table-bordered thead th {
        color: #000000 !important;
        padding: 5px;
        font-weight: bold;
        border: 2px solid #000;
    }

    /* Table body cell styles */
    table.table-bordered tbody td {
        padding: 5px;
        border: 2px solid #000;
        color: #000000 !important;
    }

    .table > :not(:last-child) > :last-child > * {
        border: 2px solid #000;
    }

    .font-sm {
        font-size: 13px !important;
    }

    .table-bordered th, .table-bordered td {
        border: 2px solid #000 !important;
    }

    #traineeTrainingViewReport {
        tr {
            $columns: 9;
            @for $i from 1 through $columns {
                th:nth-child(#{$i}) {
                    $width: auto;

                    @if $i == 1 {
                        $width: 2.8rem !important;
                    } @else if $i == 4 {
                        $width: 3rem !important;
                    } @else if $i == 6 {
                        $width: 2.5rem !important;
                    } @else if $i == 8 {
                        $width: 12rem !important;
                    } @else if $i == 9 {
                        $width: 7rem !important;
                    }

                    width: $width;
                }
            }
        }
    }

    #certificatePrint {
        .mt-5rem {
            margin-top: 10rem !important;
        }
    }

    .print-w-100{
        width: 100%;
    }
}
