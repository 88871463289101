.form-horizontal {
    .form-group {
        display: flex;

        label {
            display: flex;
            justify-content: end;
            align-items: center;
            padding-right: 1rem;
        }
    }
}

.firm-active {
    --bs-table-accent-bg: #556ee6 !important;
    color: #ffffff !important;

    td {
        color: #ffffff !important;
    }
}

.firm-danger {
    --bs-table-accent-bg: #f46a6a !important;
    color: #ffffff !important;

    td {
        color: #ffffff !important;
    }
}
